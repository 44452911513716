import React from 'react';
import ScheduleForm from './components/ScheduleForm';
import "./styles/styles.scss";

const App = () => {
    return (<div className="mod_defas_schedule mb-4 mb-lg-8">
        <ScheduleForm/>
    </div>)
}

export default App;
