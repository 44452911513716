import '../scss/beg-bahnland.scss';
import 'animate.css';
import MoreDomainsRegisterComponent from "./domain-register-fields";
import DefasSchedule from "./defas-schedule";

import Fuse from 'fuse.js';
import AutoComplete from '@tarekraafat/autocomplete.js';

function sanitizeName(value) {
    return value.trim()
        .replaceAll(/\s+/g, ' ')
        .replaceAll(/(?<!\\)(["'])/g, '\\$1')
        .trim();
}

class BegBahnland {
    static init() {
        window.dataLayer = window.dataLayer || [];
        BegBahnland.initScrollAnimation();
        BegBahnland.initDefasSchedule();
        BegBahnland.initBannerTrk();
        // BegBahnland.initSuperjobSession();
        // BegBahnland.initSuperjobForm();
        MoreDomainsRegisterComponent.init();
        DefasSchedule();
        BegBahnland.initFuzzyAutoComplete();

    }

    static _banners = [];
    static _intersectionObserver = null;

    static _addBanner(elm) {
        if (BegBahnland._banners.includes(elm)) {
            return;
        }
        BegBahnland._banners.push(elm);
        BegBahnland._intersectionObserver.observe(elm);
    }

    /** @returns {HTMLElement[]} */
    static _queryBanners() {
        const query = '.tns-item:not(.tns-slide-cloned), .ce_huh_hero:not(.tns-slide-cloned)';
        return Array.from(/** @type {NodeListOf<HTMLElement>} */document.querySelectorAll(query));
    }

    static _initBannerIntersections() {
        BegBahnland._intersectionObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (!entry.isIntersecting || entry.target.bannerViewed === true) return;

                const elm = entry.target;
                elm.bannerViewed = true;

                window.dataLayer.push({
                    event: 'gx.banner_view',
                    event_name: 'banner_view',
                    event_source: 'source_code',
                    banner_view: {
                        banner_title: document.title,
                        banner_type: elm.classList.contains('tns-item') ? 'carousel' : 'banner',
                        name: sanitizeName(
                            elm.querySelector('h1, h2, h3, h4, .headline')?.textContent
                            || elm.querySelector('.hero-sub-title p:first-of-type')?.textContent
                            || elm.id || ''
                        ),
                    }
                });
            });
        }, {threshold: 0.2});

        BegBahnland._queryBanners().forEach(elm => BegBahnland._addBanner(elm));
        window.banners = BegBahnland._banners;
    }

    static _initBannerMutations() {
        function checkElement(node) {
            return (node instanceof HTMLElement
                && (node.classList.contains('ce_huh_hero') || node.classList.contains('tns-item'))
                && !node.classList.contains('tns-slide-cloned'));
        }

        const mutationObserver = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.attributeName === 'class' && checkElement(mutation.target)) {
                    BegBahnland._addBanner(mutation.target);
                }
                mutation.addedNodes.forEach((node) => {
                    if (checkElement(node)) {
                        BegBahnland._addBanner(node);
                    }
                });
            });
        });

        mutationObserver.observe(document.body, {
            childList: true,
            subtree: true,
            attributes: true
        });
    }

    static _bindBannerClick() {
        function handleBannerClick(banner) {
            if (banner?.closest('.tiny-slider-container') === null) {
                return;
            }

            if (banner.bannerClicked === true) return;
            banner.bannerClicked = true;

            const slide = banner.closest('.tiny-slider-container > *');

            if (!slide) {
                return;
            }

            let index = -1;
            if (slide && slide.parentNode instanceof Element) {
                const allSlides = slide.parentNode.querySelectorAll(':scope > *:not(.tns-slide-cloned)');
                index = Array.prototype.indexOf.call(allSlides, slide);
            }

            const data = {
                event: 'gx.banner_select',
                event_name: 'banner_select',
                event_source: 'source_code',
                banner_select: {
                    banner_title: document.title,
                    banner_type: 'carousel',
                    name: sanitizeName(
                        slide.querySelector('h1, h2, h3, h4')?.innerText?.trim()
                        || slide.querySelector('.hero-sub-title p:first-of-type')?.innerText?.trim()
                        || slide.id
                        || ''
                    ),
                }
            };

            if (index !== -1) {
                data.banner_select.slot = index + 1;
            }

            (window.dataLayer || []).push(data);
        }

        document.addEventListener('click', ev => {
            const banner = ev.target.closest('.tns-item');
            if (!banner) return;
            handleBannerClick(banner);
        });
    }

    static initBannerTrk() {
        BegBahnland._initBannerIntersections();
        BegBahnland._initBannerMutations();
        BegBahnland._bindBannerClick();
    }

    static initScrollAnimation(container = document) {
        const animatedElements = container.querySelectorAll('.anima');
        const prefix = 'animate__';
        const threshold = 100;
        animatedElements.forEach(element => {
            element.classList.add('hidden');
            let cssClasses = ['animate__animated'];
            container.addEventListener('scroll', e => {
                let classList = element.classList;
                if (element.dataset.anima !== '1') {
                    classList.forEach(cssClass => {
                        if (cssClass.startsWith(prefix)) {
                            cssClasses.push(cssClass);
                            element.classList.remove(cssClass);
                        }
                    });
                }
                if (BegBahnland.isInViewport(container, element)) {
                    if (element.dataset.anima !== '1') {
                        setTimeout(() => {
                            element.classList.remove('hidden');
                            DOMTokenList.prototype.add.apply(element.classList, cssClasses);
                        }, threshold);
                        element.dataset.anima = '1';
                    }
                }
            }, {passive: true});
        });
    }


    static initSuperjobSession() {
        // Überprüfen, ob ein Name in der Session gespeichert ist
        if (storedName) {
            var namePlaceholders = document.querySelectorAll('.name-placeholder');
            namePlaceholders.forEach(function (placeholder) {
                placeholder.innerText = storedName;
            });

            document.querySelector('.display-name').innerText = storedName;
        }
    }

    /*static initSuperjobForm() {
        document.querySelector('.name-form').addEventListener('submit', function(event) {
            event.preventDefault();

            var nameInput = document.querySelector('.name-input');
            var name = nameInput.value;

            // Speichern des Namens in der Session über AJAX
            fetch('', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: 'name=' + encodeURIComponent(name)
            }).then(function(response) {
                return response.text();
            }).then(function() {
                if (name) {
                    // Seite neu laden mit Name in der URL
                    window.location.href = window.location.pathname + '?name=' + encodeURIComponent(name);
                }
            });

            // Leere das Eingabefeld
            nameInput.value = '';
        });
    }*/


    static isInViewport(container, el) {
        const TOP_THRESHOLD = 0.1;
        const BOTTOM_THRESHOLD = 0.9;
        const rect = el.getBoundingClientRect();
        return (
            rect.bottom >= ((window.innerHeight || container.documentElement.clientHeight) * TOP_THRESHOLD) &&
            rect.top <= ((window.innerHeight || container.documentElement.clientHeight) * BOTTOM_THRESHOLD)
        );
    }

    static initDefasSchedule() {

        let form = document.querySelector('.formhybrid_defas_schedule form')

        const onSelect = (value, type) => {
            form.querySelector('input[name="' + type + '"]').setAttribute('value', value);
        }

        if (form) {

            let fieldOrigin = form.querySelector('.formhybrid_defas_schedule input[name="name_origin"]');
            let fieldDestination = form.querySelector('.formhybrid_defas_schedule input[name="name_destination"]');

            document.addEventListener('huh.autocompletejs.onselection', (e) => {

                if (e.detail.field === fieldOrigin || e.detail.field === fieldDestination) {
                    onSelect(e.detail.item.selection.value.id, e.detail.field === fieldOrigin ? 'origin' : 'destination');
                }

            });

            form.querySelector('button[type="submit"]').addEventListener('click', e => {

                let dateValue = form.querySelector('input[name="itdDateDayMonthYear"]').value;
                let timeValue = form.querySelector('input[name="itdTime"]').value;
                let originValue = form.querySelector('input[name="origin"]').value;
                let destinationValue = form.querySelector('input[name="destination"]').value;

                let origin = 'origin=' + originValue;
                let destination = '&destination=' + destinationValue;
                let date = '&itdDateDayMonthYear=' + dateValue.replaceAll('.', '');
                let time = '&itdTime=' + timeValue.replaceAll(':', '');
                const direction = '&itdTripDateTimeDepArr=dep';

                let param = origin + destination + date + time + direction;

                form.querySelector('input[name="formik"]').setAttribute('value', param);
            })
        }
    }

    // Support 1 filter/list per page, adjusted for /ermaessigungsticket pages
    static initFuzzyAutoComplete() {
        let inputField = document.querySelector('[data-fuzzy-search]');

        if (typeof fuzzy_items == "undefined" || !inputField) { // this variable is defined in the list_table_discount_ticket_etc template
            return;
        }

        console.log('fuzzy activated');

        function isStandaloneURL(text) {
            const urlRegex = /^(https?:\/\/[^\s]+|www\.[^\s]+)$/i;
            return urlRegex.test(text);
        }

        const getKeys = (obj) => {
            const excludedKeys = new Set(["tstamp", "id", "pid", "sorting", "published", "ptable"]);
            return Object.keys(obj).filter(key => {
                // Check if key is not in the excluded list and its value is not empty
                // Consider value as "empty" if it's undefined, null, or an empty string
                return (
                    !excludedKeys.has(key) &&
                    obj[key] !== undefined &&
                    obj[key] !== null &&
                    obj[key] !== "" &&
                    !isStandaloneURL(obj[key])
                );
            });
        }

        const keys = getKeys(fuzzy_items[0]);

        // Fuse.js options (search keys and threshold for fuzzy matching)
        const fuseOptions = {
            keys: keys,
            includeMatches: true,
            threshold: 0.4  // adjust to control fuzzy matching sensitivity
        };

        // Initialize Fuse.js with your records
        const fuse = new Fuse(fuzzy_items, fuseOptions);

        // Initialize autocomplete with fuse.js as the source
        let conf = {
            selector: "[data-fuzzy-search]",
            threshold: 2,
            searchEngine: (query, record) => {
                const fuseSingle = new Fuse([record], {includeScore: true, includeMatches: true, threshold: 0.4});

                const results = fuseSingle.search(query).map(result => result.item);

                if (results.length > 0) {
                    // console.log('searchEngine', results);

                }
                return results[0];
            },
            data: {
                src: (query) => {
                    return fuse.search(query).map(result => result.item);
                },
                keys: keys,
                filter: (list) => {
                    // remove duplicates
                    const uniqueMap = new Map();
                    list.forEach(obj => {
                        if (!uniqueMap.has(obj.match)) {
                            uniqueMap.set(obj.match, obj);
                        }
                    });

                    // Convert the map values to an array
                    return Array.from(uniqueMap.values());
                }
            },
            resultsList: {
                class: "autocomplete_results_container",
                element: (list, data) => {
                    const info = document.createElement("p");
                    info.style.padding = "10px 20px";
                    if (data.results.length > 0) {
                        info.innerHTML = `Anzeigen <strong>${data.results.length}</strong> von <strong>${data.matches.length}</strong> ${data.matches.length > 1 ? 'Ergebnissen' : 'Ergebnis'} für <strong>"${data.query}"</strong>`;
                    } else {
                        info.innerHTML = `<strong>${data.matches.length}</strong> passende Ergebnisse für <strong>"${data.query}"</strong>`;
                    }
                    list.prepend(info);
                },
                noResults: true,
                maxResults: 15,
                tabSelect: true
            },
            resultItem: {
                class: "autocomplete_result",
                element: (item, data) => {
                    // Modify Results Item Style
                    item.style = "display: flex; justify-content: space-between;";
                    // Modify Results Item Content
                    item.innerHTML = `
      <span style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden;">
        ${data.match}
      </span>`;
                },
                highlight: "autoComplete_highlight",
                selected: "autoComplete_selected"
            },
        };

        const autoCompleteJS = new AutoComplete(conf);

        autoCompleteJS.input.addEventListener("selection", function (event) {
            const feedback = event.detail;
            autoCompleteJS.input.blur();
            // Prepare User's Selected Value
            autoCompleteJS.input.value = feedback.selection.value[feedback.selection.key];
        });

        autoCompleteJS.input.addEventListener("focus", function (event) {
            // Prepare User's Selected Value
            autoCompleteJS.start()
        });
        autoCompleteJS.input.addEventListener('blur', (e) => {
            autoCompleteJS.close();
        });

    }
}

document.addEventListener('DOMContentLoaded', BegBahnland.init, true);
