import React, {useEffect, useRef, useState} from 'react';

const App = () => {

    const btnRef = useRef(null);

    const [domains, setDomains] = useState([]);
    const [blocker, setBlocker] = useState(true);

    useEffect(() => {
        if (btnRef.current) {
            let form = btnRef.current.closest('form');
            let domainsInput = form?.querySelector('input[name="domains"]');
            if (domainsInput) {
                setDomains(JSON.parse(domainsInput.value));
                setBlocker(false)
            }

        }
    }, []);

    useEffect(() => {
        if (btnRef.current) {
            let form = btnRef.current.closest('form');
            let domainsInput = form?.querySelector('input[name="domains"]');

            if (domainsInput && !blocker) {
               domainsInput.value = domains.length > 0 ? JSON.stringify(domains) : ''
            }
        }
    }, [domains]);

    const renderInputs = (value = '', index = 0, onChange = () => null) => (
        <div className="domain form-group widget widget-text" key={index}>
            <label id={"lbl_ctrl_widget-more-domains_" + index} htmlFor={"ctrl_widget-more-domains_" + index}
                   className="form-label">
                Domain {index + 2}
            </label>
            <div className="domain-input-wrapper row">
                <div className="d-input-ctn col-12 col-sm-8">
                    <input
                        type="text"
                        name={"domains[" + index + "]"}
                        id={"ctrl_widget-more-domains_" + index}
                        className="form-control"
                        value={value}
                        onChange={e => onChange(e.target.value, index)}
                    />
                </div>

                <div className="del-btn-wrapper col-12 col-sm-4 mt-2 mt-sm-0">
                    <button type="button" className="btn btn-danger" onClick={removeDomain(index)}>Entfernen</button>

                </div>
            </div>

        </div>

    );

    const addDomainInput = () => {
        setDomains([...domains, '']);
    }

    const removeDomain = (index) => () => {
        const newDomains = [...domains];
        newDomains.splice(index, 1);
        setDomains(newDomains);
    }

    const onChangeInput = (value, index) => {
        const newDomains = [...domains];
        newDomains[index] = value;
        setDomains(newDomains);
    }

    return <div className="widget-more-domains" ref={btnRef}>
        <button type="button" className="btn btn-primary mb-3" data-bs-toggle="modal" data-bs-target="#registerWidgetModal">
            Weitere Domains ({domains.length})
        </button>

        <div className="modal fade" id="registerWidgetModal" tabIndex="-1" aria-labelledby="registerWidgetModalLabel"
             aria-hidden="true">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="registerWidgetModalLabel">Weiteren Domains hinzufügen</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body" style={Styles.modal.body}>
                        {domains.map((domain, index) => renderInputs(domain, index, onChangeInput))}
                        <button type="button" className="btn btn-primary" onClick={addDomainInput}>
                            Weitere Domain hinzufügen +1
                        </button>

                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Schließen</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

const Styles = {
    modal: {
        body: {
            maxHeight: '60vh',
            overflowY: 'auto'
        }
    }
}

export default App;