import React, { Fragment, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import { getStops } from "../routes";

const AutocompleteField = (props) => {
    const { label, value, handleChange, placeholder, additionalClasses = "", name } = props;

    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);

    const updateOptions = async (value) => {
        setLoading(true);
        await getStops(value)
            .then((value) => {
                setOptions(value);
                setLoading(false);
            })
            .catch((e) => setLoading(false));
    };

    return (
        <Autocomplete
            className={"combo-box-container form-group " + additionalClasses}
            options={options}
            filterOptions={(x) => x}
            noOptionsText={"kein Vorschlag"}
            getOptionLabel={(option) => option.name ?? option}
            fullWidth
            isOptionEqualToValue={(option, value) => true}
            onInputChange={(e, value, reason) => {
                if (reason === "input" && value.length > 2) {
                    updateOptions(value);
                } else {
                    setOptions([]);
                }
            }}
            name={name}
            onChange={(e, value) => handleChange(value)}
            value={value}
            renderInput={(params) => (
                // <TextField
                //     {...params}
                //     placeholder={placeholder}
                //     label={label}
                //     InputLabelProps={{
                //         className: "form-label"
                //     }}
                //     InputProps={{
                //         ...params.InputProps,
                //         autoComplete: "off", //work around to prevent browser autofill
                //         "aria-label": label,
                //         endAdornment: (
                //             <Fragment>
                //                 {loading ? (
                //                     <CircularProgress color="inherit" size={20} />
                //                 ) : null}
                //                 {params.InputProps.endAdornment}
                //             </Fragment>
                //         ),
                //     }}
                // />
                <div className="form-group widget widget-text">
                    <label htmlFor={"input-combobox-mui-"+name}>{label}</label>
                <div ref={params.InputProps.ref}>
                    <input
                        {...params.inputProps}
                        className={`form-control form-group input-group ${params.inputProps.className || ""}`}
                        type="text"
                        id={"input-combobox-mui-"+name}
                         />
                </div>
                </div>
            )}
        />
    );
};

export default AutocompleteField;
