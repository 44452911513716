import React, {useRef} from 'react';
import {Formik, Field, Form} from "formik";
import AutocompleteField from "./AutocompleteField";
import Flatpickr from "react-flatpickr";
// import "flatpickr/dist/themes/material_blue.css";
// import 'flatpickr/dist/plugins/monthSelect/style.css';
import '../styles/flatpickr.css';
import 'flatpickr/dist/plugins/monthSelect/style.css';
import '../styles/flatpickr-bundle.scss';
import {German} from "flatpickr/dist/l10n/de.js";
import moment from 'moment';
import {FormControl, FormControlLabel, Radio, RadioGroup} from "@mui/material";

const ScheduleForm = () => {
    const fp = useRef(null);
    const fp2 = useRef(null);
    const onSubmit = (values) => {
        // console.log('form', values);

        let origin = 'origin=' + values.origin.id;
        let destination = '&destination=' + values.destination.id;
        let time = '&itdTime=' + moment(values.time).format('HHmm');
        let date = '&itdDateDayMonthYear=' + moment(values.date).format('DDMMYYYY');
        // let direction = '&itdTripDateTimeDepArr=' + values.direction
        let direction = '&itdTripDateTimeDepArr=dep'
        let encoded = encodeURIComponent(origin + destination + date + time + direction)
        let url = "https://bahnland-bayern.de/moby/efa/app/trip?formik=" + encoded;
        // console.log('url', url)
        window.location.href = url;
    }

    const renderForm = ({
                            values,
                            setFieldValue
                        }
    ) => (
        <Form className="defas-form">
            <div className="formbody">


            <AutocompleteField
                label={"Startpunkt"}
                placeholder={"z.B. Haltestelle, Adresse, Einkaufsort, ..."}
                value={values.origin.name ?? ""}
                handleChange={(value) => setFieldValue("origin", value ?? '')}
                additionalClasses={"name_origin"}
                name="origin"
            />

            <AutocompleteField
                label={"Zielpunkt"}
                placeholder={"z.B. Haltestelle, Adresse, Einkaufsort, ..."}
                value={values.destination.name ?? ""}
                handleChange={(value) => setFieldValue("destination", value ?? '')}
                additionalClasses={"name_destination"}
name={"destination"}
            />

            {/*<RadioGroup*/}
            {/*    row*/}
            {/*    name="direction"*/}
            {/*    onChange={e => {*/}
            {/*        setFieldValue('direction', e.target.value)*/}
            {/*    }}*/}
            {/*    value={values.direction}*/}
            {/*>*/}
            {/*    <FormControlLabel value="dep" control={<Radio/>} label="Abfahrt"/>*/}
            {/*    <FormControlLabel value="arr" control={<Radio/>} label="Ankunft"/>*/}
            {/*</RadioGroup>*/}
            {/*<div className="row date-picker-container ">*/}
                <div className="itdDateDayMonthYear form-group flatpickr">
                    <label className="form-label" id="ctrl-defas-idtDate" htmlFor={"ctrl-defas-idtDate"}>Datum</label>
                    <div className="input-group">
                        <button className="btn datepicker" type="button" tabIndex="-1" data-toggle=""
                                onClick={() => {
                                    if (!fp?.current?.flatpickr) return;
                                    fp.current.flatpickr.toggle()
                                }}>
                            <i className="icon-datepicker" aria-hidden="true"></i>
                            <span>Datepicker</span>
                        </button>
                        <Flatpickr
                            className="form-control form-group flatpickr-input"
                            options={{
                                dateFormat: "d.m.Y",
                                locale: German,
                                disableMobile: true,
                                minDate: moment().format('DD.MM.YYYY')
                            }}
                            value={values.date}
                            onChange={(date) => setFieldValue("date", date[0])}
                            aria-labelledby="ctrl-defas-idtDate"
                            ref={fp}
                            id={"ctrl-defas-idtDate"}
                        />
                    </div>
                </div>
                <div className="itdTime form-group flatpickr">
                    <label className="form-label" id="ctrl-defas-idtTime" htmlFor={"ctrl-defas-idtTime"}>Zeit</label>
                    <div className="input-group">
                        <button className="btn timepicker" type="button" tabIndex="-1" data-toggle=""
                                onClick={() => {
                                    if (!fp2?.current?.flatpickr) return;
                                    fp2.current.flatpickr.toggle()
                                }}>
                            <i className="icon-timepicker" aria-hidden="true"></i>
                            <span>Timepicker</span>
                        </button>
                        <Flatpickr
                            className="form-control form-group flatpickr-input"
                            options={{
                                enableTime: true,
                                noCalendar: true,
                                dateFormat: "H:i",
                                time_24hr: true,
                                disableMobile: true
                            }}
                            value={values.time}
                            onChange={(time) => setFieldValue("time", time[0])}
                            aria-labelledby="ctrl-defas-idtTime"
                            id={"ctrl-defas-idtTime"}
                            ref={fp2}
                        />
                    </div>

                </div>
            {/*</div>*/}
            <div className=" form-group widget widget-submit">
                <button className="btn btn-primary btn btn-primary btn-lg" aria-label="Dieses Formular absenden" type="submit">
                    Suchen
                </button>
            </div>
            </div>
        </Form>
    );

    return (<Formik
        initialValues={{
            origin: {
                name: "",
                id: ""
            },
            destination: {
                name: "",
                id: ""
            },
            date: new Date(),
            time: new Date(),
            direction: "dep"
        }}
        onSubmit={onSubmit}
        component={renderForm}
    />)
}

export default ScheduleForm;
