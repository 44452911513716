import axios from "axios";

const stopApi = "/api/defas_schedule/location/";

export const getStops = async (value) => {
    let result = [];

    await axios.get(stopApi + value).then((response) => {
        if (response.status === 200) {
            // console.log('get stop', response)
            result = response.data;
        }
    });

    return result;
};
