
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

const MoreDomainsRegisterComponent = {
    init: () => {
        const rootContainer = document.getElementById('beg-widget-more-domains');
        if (rootContainer) {
            const root = ReactDOM.createRoot(rootContainer);
            root.render(
                <React.StrictMode>
                    <App />
                </React.StrictMode>
            );
        }
    }
}

export default MoreDomainsRegisterComponent;