import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

const init =() => {
    const rootContainer = document.getElementById('root-defas');
// console.log('rootContainer', rootContainer);
    if (rootContainer) {
        const root = ReactDOM.createRoot(rootContainer);
        root.render(
            <React.StrictMode>
                <App />
            </React.StrictMode>
        );
    }
}

export default init;
